@font-face {
  font-family: 'salescamp';
  src: url('../font/salescamp.eot?e0m5sg');
  src: url('../font/salescamp.eot?e0m5sg#iefix') format('embedded-opentype'),
    url('../font/salescamp.ttf?e0m5sg') format('truetype'), url('../font/salescamp.woff?e0m5sg') format('woff'),
    url('../font/salescamp.svg?e0m5sg#salescamp') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'salescamp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-account:before {
  content: '\e900';
}
.icon-add-account:before {
  content: '\e901';
}
.icon-add-channel:before {
  content: '\e902';
}
.icon-add-notes:before {
  content: '\e903';
}
.icon-add-number:before {
  content: '\e904';
}
.icon-add-plus:before {
  content: '\e905';
}
.icon-assign:before {
  content: '\e906';
}
.icon-attachment:before {
  content: '\e907';
}
.icon-attachment-link:before {
  content: '\e975';
}
.icon-audio-pause:before {
  content: '\e976';
}
.icon-audio-play:before {
  content: '\e977';
}
.icon-backspace:before {
  content: '\e908';
}
.icon-big-down-arrow:before {
  content: '\e909';
}
.icon-big-left-arrow:before {
  content: '\e90a';
}
.icon-big-right-arrow:before {
  content: '\e90b';
}
.icon-big-up-arrow:before {
  content: '\e90c';
}
.icon-bold:before {
  content: '\e90d';
}
.icon-bookmark:before {
  content: '\e90e';
}
.icon-bullet-list:before {
  content: '\e90f';
}
.icon-buy-number:before {
  content: '\e910';
}
.icon-calendar:before {
  content: '\e911';
}
.icon-call:before {
  content: '\e912';
}
.icon-check:before {
  content: '\e913';
}
.icon-checked-round:before {
  content: '\e914';
}
.icon-clicked:before {
  content: '\e915';
}
.icon-close:before {
  content: '\e917';
}
.icon-close-round:before {
  content: '\e916';
}
.icon-code:before {
  content: '\e918';
}
.icon-column-chooser:before {
  content: '\e919';
}
.icon-companies:before {
  content: '\e91a';
}
.icon-contact:before {
  content: '\e91b';
}
.icon-copy:before {
  content: '\e91c';
}
.icon-create-meeting:before {
  content: '\e91d';
}
.icon-customize:before {
  content: '\e91e';
}
.icon-dashboard:before {
  content: '\e91f';
}
.icon-deals:before {
  content: '\e920';
}
.icon-delete:before {
  content: '\e921';
}
.icon-details:before {
  content: '\e922';
}
.icon-document:before {
  content: '\e923';
}
.icon-dots-vertical:before {
  content: '\e924';
}
.icon-down-arrow:before {
  content: '\e925';
}
.icon-download:before {
  content: '\e926';
}
.icon-draft:before {
  content: '\e927';
}
.icon-edit:before {
  content: '\e929';
}
.icon-edit-pen:before {
  content: '\e928';
}
.icon-email:before {
  content: '\e92b';
}
.icon-email-open:before {
  content: '\e92a';
}
.icon-erase:before {
  content: '\e92c';
}
.icon-essential:before {
  content: '\e92d';
}
.icon-export:before {
  content: '\e92e';
}
.icon-feedback:before {
  content: '\e92f';
}
.icon-fill-notifications:before {
  content: '\e97a';
}
.icon-fill-star:before {
  content: '\e97b';
}
.icon-filter-sort:before {
  content: '\e930';
}
.icon-flag:before {
  content: '\e931';
}
.icon-follow-up:before {
  content: '\e932';
}
.icon-forward:before {
  content: '\e933';
}
.icon-goal:before {
  content: '\e934';
}
.icon-hide:before {
  content: '\e935';
}
.icon-idea:before {
  content: '\e936';
}
.icon-import:before {
  content: '\e938';
}
.icon-import-data:before {
  content: '\e937';
}
.icon-inbox:before {
  content: '\e939';
}
.icon-infographic:before {
  content: '\e93a';
}
.icon-information:before {
  content: '\e93b';
}
.icon-ingoing:before {
  content: '\e978';
}
.icon-invoice:before {
  content: '\e93c';
}
.icon-italic:before {
  content: '\e93d';
}
.icon-join:before {
  content: '\e93e';
}
.icon-layout:before {
  content: '\e93f';
}
.icon-lead:before {
  content: '\e940';
}
.icon-left-arrow:before {
  content: '\e941';
}
.icon-link:before {
  content: '\e942';
}
.icon-list:before {
  content: '\e943';
}
.icon-location:before {
  content: '\e944';
}
.icon-mail-opened:before {
  content: '\e945';
}
.icon-massage:before {
  content: '\e946';
}
.icon-max-mius:before {
  content: '\e947';
}
.icon-menu:before {
  content: '\e948';
}
.icon-messenger:before {
  content: '\e949';
}
.icon-minus:before {
  content: '\e94a';
}
.icon-more:before {
  content: '\e94b';
}
.icon-next:before {
  content: '\e94d';
}
.icon-next-week:before {
  content: '\e94c';
}
.icon-note:before {
  content: '\e94e';
}
.icon-notifications:before {
  content: '\e97c';
}
.icon-numbers:before {
  content: '\e94f';
}
.icon-other:before {
  content: '\e950';
}
.icon-outgoing:before {
  content: '\e979';
}
.icon-phone:before {
  content: '\e951';
}
.icon-pluse:before {
  content: '\e952';
}
.icon-porting:before {
  content: '\e953';
}
.icon-printer:before {
  content: '\e954';
}
.icon-quote:before {
  content: '\e955';
}
.icon-refresh:before {
  content: '\e956';
}
.icon-repeat:before {
  content: '\e957';
}
.icon-reply:before {
  content: '\e958';
}
.icon-resource:before {
  content: '\e959';
}
.icon-rich-text:before {
  content: '\e95a';
}
.icon-right-arrow:before {
  content: '\e95b';
}
.icon-save:before {
  content: '\e95c';
}
.icon-search:before {
  content: '\e95d';
}
.icon-share:before {
  content: '\e95e';
}
.icon-show:before {
  content: '\e95f';
}
.icon-star:before {
  content: '\e960';
}
.icon-stop:before {
  content: '\e961';
}
.icon-strike:before {
  content: '\e962';
}
.icon-t-delete:before {
  content: '\e963';
}
.icon-t-fomat:before {
  content: '\e964';
}
.icon-tag:before {
  content: '\e965';
}
.icon-task:before {
  content: '\e966';
}
.icon-team:before {
  content: '\e967';
}
.icon-thumbs-up:before {
  content: '\e968';
}
.icon-timer:before {
  content: '\e969';
}
.icon-today:before {
  content: '\e96a';
}
.icon-toggle:before {
  content: '\e96b';
}
.icon-tomorrow:before {
  content: '\e96c';
}
.icon-tools:before {
  content: '\e96d';
}
.icon-two-way:before {
  content: '\e96e';
}
.icon-underline:before {
  content: '\e96f';
}
.icon-up-arrow:before {
  content: '\e970';
}
.icon-user:before {
  content: '\e971';
}
.icon-video:before {
  content: '\e972';
}
.icon-webcam:before {
  content: '\e973';
}
.icon-whatsapp:before {
  content: '\e974';
}
